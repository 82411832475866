/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react'
import { Button, Avatar, Dropdown, Image, Menu } from 'antd'
import expandIcon from '@/assets/svg/expand-icon.svg'
import { userRolesForDisplay } from '@/utils/helpers'
import logOutIcon from '@/assets/svg/menu/log-out.svg'
import { MoreOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { logout } from '@/views/Login/ducks/actions'
import { useHistory } from 'react-router-dom'
import { LocationIcon, UserIcon } from '@/components/IconsCustom'
import { useAppSelector } from '@/redux/store'

type Props = {
  navType: string
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

const LocationSidebar = ({ navType, collapsed, setCollapsed }: Props) => {
  const user = useAppSelector((state) => state?.user?.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const avatar = useMemo(() => user?.image ?? '', [user?.image])

  const handleLogout = (): void => {
    dispatch(logout())
  }

  const menuKabab = (
    <Menu
      className="sidebar_menu"
      items={[
        {
          key: '1',
          title: '',
          label: (
            <div className="user_details">
              <Avatar size={40} src={avatar} className="avatar" />
              <div className="user_info">
                <h3>{user?.name}</h3>
                <p>{userRolesForDisplay(user?.role as string)}</p>
              </div>
            </div>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: '5',
          title: '',
          label: (
            <Button className="sidebar_button" onClick={handleLogout}>
              <Image src={logOutIcon} width={20} preview={false} />
              <div className="menu_label">Log out</div>
            </Button>
          ),
        },
      ]}
    />
  )

  const collapsable = () => {
    localStorage.setItem('collapsable', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  return (
    <div
      className={collapsed ? 'location_newsidebar_menu small_sidebar' : 'location_newsidebar_menu'}
    >
      {/* <div className="sidebar_logo">
        <Image preview={false} src={logoIcon} width={28} />
        {!collapsed && <Image preview={false} src={logoText} width={77} />}
      </div> */}

      <div className="navigation nav_without_hotel">
        <div className="topNav">
          <div
            aria-hidden="true"
            className={window.location.pathname.includes('locations') ? 'nav_li active' : 'nav_li'}
            onClick={() => history.push('/manager/locations')}
          >
            <LocationIcon />
            {!collapsed && <span className="nav_label">Locations</span>}
          </div>

          <div
            aria-hidden="true"
            className={window.location.pathname.includes('users') ? 'nav_li active' : 'nav_li'}
            onClick={() => history.push('/manager/users')}
          >
            <UserIcon />
            {!collapsed && <span className="nav_label">Users</span>}
          </div>

          {/* <div aria-hidden="true" className="nav_li">
            <ReportsIcon />
            <span className="nav_label">Reports</span>
          </div> */}
        </div>
      </div>
      <div className="bottomNav">
        <div className="navItemLink">
          <div className="user_details">
            <Dropdown
              overlay={menuKabab}
              trigger={['click']}
              placement="topLeft"
              overlayClassName="side_dropdown"
            >
              <div
                className={
                  user?.user_type !== 'location_user'
                    ? 'sidebar_menu_open'
                    : 'sidebar_menu_open only_email'
                }
              >
                <Avatar src={avatar} className="avatar" />
                <div className="user_info">
                  <h3>{user?.name ?? user?.email}</h3>
                  <p>{userRolesForDisplay(user?.role ?? '')}</p>
                </div>
                <div className="more_options">
                  <MoreOutlined />
                </div>
              </div>
            </Dropdown>
            <div className="user_btn_options">
              <div className="expand_icon">
                <Button type="link" onClick={collapsable}>
                  <Image preview={false} src={expandIcon} width={16} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationSidebar
